
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import UserModel from '../../models/Users/UserModel'

export type StatusListType = Array<{ status: string, label: string }>

@Component({})
export default class UserUiCard extends Vue {
  @Prop({ required: true }) value: UserModel
  @Prop({ required: false, default: false }) isUpdateDialog: boolean
  @Prop({ required: false, default: null }) selectedId: Number
  @Prop({ required: false, default: false }) isProcessing: boolean

  newUser: UserModel = new UserModel({})
  mailSuffixes: Array<string> = []
  MAIL_SUFFIX: string = '@doubleverify.com' // default
  hintCanNotModifyStatus: string = 'Can not modify status if current user has not this status.'
  statusList: StatusListType = [
    { status: 'isAm', label: 'am' },
    { status: 'isSale', label: 'sale' },
    { status: 'isDebugger', label: 'debugger' },
    { status: 'isSettupper', label: 'settupper' },
    { status: 'isAdminAndFinance', label: 'admin_and_finance' }
  ]
  mailSuffixesLoading: boolean = false

  async mounted () {
    this.newUser = this.value
    await this.setMailSuffixes()
  }

  async setMailSuffixes () {
    this.mailSuffixesLoading = true
    const result = await this.$apiCaller.getOrgaAllowedAuthDomains('doubleverify')
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('Error when calling getAllowedAuthDomains')
      this.$store.commit('setErrorMessageWithResponse', result)
      this.mailSuffixesLoading = false
    } else {
      if (result.data) {
        this.mailSuffixes = result.data
        this.mailSuffixesLoading = false
      }
    }
  }

  // EMITTERS
  emitClose () {
    this.$emit('close')
  }
  emitCreate () {
    if (!this.hasUserMail) {
      return
    }
    this.newUser.dvMail = `${this.newUser.mail}${this.MAIL_SUFFIX}`
    this.$emit('create')
  }

  // GETTERS SETTERS
  get titleDialog () {
    return this.isUpdateDialog ? `Update user id ${this.selectedId}` : 'New user'
  }
  get confirmBtnLabel () {
    return this.isUpdateDialog ? 'Update' : 'Create'
  }
  get hasUserMail () {
    return !((this.newUser.mail == null || this.newUser.mail === '') && (this.newUser.dvMail == null || this.newUser.dvMail === ''))
  }

  // WATCHERS
  @Watch('newUser')
  onNewUserChange (newValue: UserModel) {
    this.$emit('input', newValue)
  }
  @Watch('value')
  async onValueChange (newValue: UserModel) {
    if (newValue !== this.newUser) {
      this.newUser = newValue
    }
    await this.setMailSuffixes()
  }
}
